<template>
  <div class="relative">
    <Spin v-if="loading" fix></Spin>
    <h4 class="tip" v-if="loading">Jumping to the forum...</h4>
    <h4 class="tip" v-else>
      <a :href="forumUrl" target="_blank"
        >You can click here to go the forum again.</a
      >
    </h4>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
const { _queryForum } = api;

export default {
  components: {
    // empty
  },
  computed: {
    ...mapState("user", ["company_name", "role"]),
    list() {
      return this[this.tab];
    }
  },
  data() {
    return {
      loading: false,
      forumUrl: ""
    };
  },

  created() {
    this.loading = true;
    _queryForum().then(response => {
      const { data } = response;
      this.loading = false;
      this.forumUrl = data.url;
      window.open(data.url, "_blank");
    });
  },

  methods: {}
};
</script>

<style lang="less" scoped>
.relative {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tip {
  text-align: center;
  font-size: 16px;
  padding: 200px 0;
  color: @primary-color;
  a {
    color: @primary-color;
    text-decoration: underline;
  }
}
</style>
